import React from 'react'
import GlobalContextProvider from './src/context/GlobalContextProvider'
import Firebase, { FirebaseContext } from './src/components/firebase/firebase'
/* Move CSS import to gatsby-browser */
import './src/assets/css/main.css'
import Amplify from 'aws-amplify'
import awsconfig from './src/aws-exports'

Amplify.configure(awsconfig)

export const wrapRootElement = ({ element }) => {
  return (
    <FirebaseContext.Provider value={new Firebase()}>
      <GlobalContextProvider>{element}</GlobalContextProvider>
    </FirebaseContext.Provider>
  )
}
