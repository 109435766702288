import React from 'react'
import app from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'

const FirebaseContext = React.createContext(null)

// Firebase config
const config = {
  apiKey: 'AIzaSyDK7KPyxT5sMQBoIYVh8XdwvDQ4CkEtvS0',
  authDomain: 'crowd-supply-dev.firebaseapp.com',
  databaseURL: 'https://crowd-supply-dev.firebaseio.com',
  projectId: 'crowd-supply-dev',
  storageBucket: 'crowd-supply-dev.appspot.com',
  messagingSenderId: '592312545449',
  appId: '1:592312545449:web:c1d6d79a6b3ee6493eb9b8',
  measurementId: 'G-087MMHJWFJ',
}

class Firebase {
  constructor() {
    if (typeof window !== 'undefined') {
      app.initializeApp(config)
      this.auth = app.auth()
      this.db = app.database()
      this.googleProvider = new app.auth.GoogleAuthProvider()
      this.facebookProvider = new app.auth.FacebookAuthProvider()
    }
  }

  user = uid => this.db.ref(`users/${uid}`)

  users = () => this.db.ref('users')
}

export default Firebase
export { FirebaseContext }
