// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mystore-jsx": () => import("./../../src/pages/mystore.jsx" /* webpackChunkName: "component---src-pages-mystore-jsx" */),
  "component---src-pages-profile-jsx": () => import("./../../src/pages/profile.jsx" /* webpackChunkName: "component---src-pages-profile-jsx" */),
  "component---src-pages-search-jsx": () => import("./../../src/pages/search.jsx" /* webpackChunkName: "component---src-pages-search-jsx" */),
  "component---src-pages-shareit-jsx": () => import("./../../src/pages/shareit.jsx" /* webpackChunkName: "component---src-pages-shareit-jsx" */),
  "component---src-pages-thankyou-jsx": () => import("./../../src/pages/thankyou.jsx" /* webpackChunkName: "component---src-pages-thankyou-jsx" */)
}

