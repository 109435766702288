import React, { createContext, useReducer } from 'react'
import { Auth } from 'aws-amplify'
import { navigate } from 'gatsby'
import axios from 'axios'

export const GlobalStateContext = createContext()
export const GlobalDispatchContext = createContext()
export const GlobalAuthContext = createContext()
export const GlobalCategoriesContext = createContext()

const initialState = {
  gridView: false,
  searchNearbyURI:
    'https://maps.googleapis.com/maps/api/place/nearbysearch/json?',
  searchResult: void 0,
  userData: void 0,
  categories: void 0,
  searchData: [],
  userLocation: {},
  formattedAddress: '',
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'GRID_VIEW': {
      return {
        ...state,
        gridView: true,
      }
    }
    case 'MAP_VIEW': {
      return {
        ...state,
        gridView: false,
      }
    }
    case 'VALIDATE_SESSION': {
      Auth.currentAuthenticatedUser({})
        .then(session => {
          return {
            ...state,
            userData: session,
          }
        })
        .catch(err => {
          navigate('/')
          console.log('Error getting session', err)
        })
      return { ...state }
    }
    case 'PRELOAD_CATEGORIES': {
      return {
        ...state,
        categories: action.payload,
      }
    }
    case 'SET_SEARCHRESULTS': {
      return {
        ...state,
        searchResult: action.payload,
      }
    }
    default:
      throw new Error('Bad Action Type')
  }
}

const authenticate = async (email, password) =>
  await Auth.signIn(email.toLowerCase(), password)

const getAuthenticatedUser = async () => await Auth.currentAuthenticatedUser()

const signUp = async (Email, Password) =>
  await Auth.signUp({
    username: Email,
    password: Password,
  })

const forgotPassword = async username =>
  await Auth.forgotPassword(username)
    .then(data => console.log(data))
    .catch(err => console.log(err))

// Collect confirmation code and new password, then
const forgotPasswordSubmit = async (username, new_password, code) =>
  await Auth.forgotPasswordSubmit(username, code, new_password)
    .then(data => console.log(data))
    .catch(err => console.log(err))

const signOut = async () => {
  try {
    await Auth.signOut()
    navigate('/')
  } catch (error) {
    console.log('error signing out: ', error)
  }
}

const fetchCategories = async () => {
  const user = await Auth.currentAuthenticatedUser()
  const token = user.signInUserSession.idToken.jwtToken
  return await axios.get(
    'https://px9pnewqm4.execute-api.us-east-1.amazonaws.com/Prod/categories',
    {
      headers: {
        Authorization: token,
      },
    }
  )
}

const storeDataUpload = async payload => {
  const user = await Auth.currentAuthenticatedUser()
  const token = user.signInUserSession.idToken.jwtToken
  return await axios.post(
    'https://qo0dxtalca.execute-api.us-east-1.amazonaws.com/prod/imageUpload',
    payload,
    {
      headers: {
        'Content-Type': 'text/plain',
        Authorization: token,
      },
    }
  )
}

/*const searchStores = async (lat, lng, itemIds) => {
  const user = await Auth.currentAuthenticatedUser()
  const token = user.signInUserSession.idToken.jwtToken
  return await axios.get(
    `https://jmm0i2fucd.execute-api.us-east-1.amazonaws.com/Prod/search?lat=${lat}&lng=${lng}&categories=${itemIds.toString()}`,
    {
      headers: {
        Authorization: token,
      },
    }
  )
}*/
const searchStores = async ({
  lat,
  lng,
  searchQuery,
  searchType,
  itemsIds,
}) => {
  const user = await Auth.currentAuthenticatedUser()
  const token = user.signInUserSession.idToken.jwtToken
  const urlString = `lat=${lat}&lng=${lng}&categories=${
    itemsIds ? itemsIds.toString() : ''
  }&searchType=${searchType}&searchQuery=${searchQuery}`
  return await axios.get(
    `https://a4oxav613l.execute-api.us-east-1.amazonaws.com/dev/categories?${urlString}`,
    {
      headers: {
        Authorization: token,
      },
    }
  )
}

const reverseGeolocation = async currentLocation => {
  let response = await axios.get(
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${currentLocation.lat},${currentLocation.lng}&key=AIzaSyAQhra2X6N6O4LcJpll2Y1qJzFaDuwvMbY`
  )
  let data = await response.data
  return data
}

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <GlobalAuthContext.Provider
      value={{
        authenticate,
        signOut,
        signUp,
        forgotPassword,
        forgotPasswordSubmit,
        getAuthenticatedUser,
      }}
    >
      <GlobalStateContext.Provider value={state}>
        <GlobalDispatchContext.Provider value={dispatch}>
          <GlobalCategoriesContext.Provider
            value={{
              fetchCategories,
              searchStores,
              storeDataUpload,
              getAuthenticatedUser,
              reverseGeolocation,
            }}
          >
            {children}
          </GlobalCategoriesContext.Provider>
        </GlobalDispatchContext.Provider>
      </GlobalStateContext.Provider>
    </GlobalAuthContext.Provider>
  )
}

export default GlobalContextProvider
