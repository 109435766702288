/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'us-east-1',
  aws_cognito_identity_pool_id:
    'us-east-1:480a298e-5b41-4b08-9456-e255e7d12f86',
  aws_cognito_region: 'us-east-1',
  //"aws_user_pools_id": "us-east-1_DTwgmuDF3",
  aws_user_pools_id: 'us-east-1_q0JITODaE',
  //"aws_user_pools_web_client_id": "1n44ar427nou9tc319ide0gpvt",
  aws_user_pools_web_client_id: '2p6e9pvvvp3v53hqcle5o613fe',
  oauth: {
    // Domain name //********.auth.us-east-1.amazoncognito.com
    domain: 'crowdsupply-crowdsupply-dev.auth.us-east-1.amazoncognito.com',

    // Authorized scopes
    scope: [
      'phone',
      'email',
      'profile',
      'openid',
      'aws.cognito.signin.user.admin',
    ],

    // Callback URL
    redirectSignIn: 'https://dev.crowdsupply.us/search', // http://www.example.com/signin/ or 'exp://127.0.0.1:19000/--/', 'myapp://main/'

    // Sign out URL
    redirectSignOut: 'https://dev.crowdsupply.us/', // 'http://www.example.com/signout/' or 'exp://127.0.0.1:19000/--/', 'myapp://main/'

    // 'code' for Authorization code grant,
    // 'token' for Implicit grant
    // Note that REFRESH token will only be generated when the responseType is code
    responseType: 'token',

    // optional, for Cognito hosted ui specified options
  },
  federationTarget: 'COGNITO_USER_POOLS',
}

export default awsmobile
